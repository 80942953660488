import { useEffect } from 'react';
import { useRouter } from 'next/router';

import ErrorPage from '../components/ErrorPage';

const Error404 = (props) => {
  const router = useRouter();

  useEffect(() => {
    let timer = null;
    timer = setTimeout(() => router.push('/all-promotions'), 5000);

    return () => clearTimeout(timer);
  }, [router]);

  return <ErrorPage {...props} code={404} />;
};

export default Error404;
